import React from 'react';
import styled from 'styled-components';

const Results = styled.div`
  height: 100%;
  width: 100%;
  transition: all 0.25s ease-in-out;
  p a {
    color: #467FB1;
  }
`

const Ul = styled.ul`
  list-style: none;
  padding-left: 0px;
  margin-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
`

const Li = styled.li`
  border-bottom: 2px dotted #ddd;
  padding-left: 0px;
  margin-left: 0px;
  padding-top: 6px;
  padding-bottom: 6px;
  span:nth-child(2) {
    font-variant-numeric: tabular-nums;
    padding-right: 10px;
    @media (max-width: 768px) {
      margin-left: 28px;
    }
  }
  a {
    width: 100%;
    display: flex;
    justify-content: space-between;
    transition: all 0.25s ease-in-out;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    &:hover {
      color: #467FB1;
    }
  }
`

function plus27(phonenumber) {
  return('tel:+27'+phonenumber.slice(1));
}

function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return null
}

const Numberlist = (props) => {

  const { numbers, searchFilter } = props;
  const searchterm = searchFilter ? searchFilter.toLowerCase() : false;

  if(searchterm) {

    numbers.sort((a, b) => (a.name > b.name) ? 1 : -1);

    const results = numbers.filter(number => number.name.toLowerCase().includes(searchterm) || number.tags.toLowerCase().includes(searchterm));

    if(results.length === 0) {

      return(
        <Results>
          <h3>Search results</h3>
          <p>No results found - <a href="mailto:hello@pierre.io">submit a new phone number?</a></p>
        </Results>
      );

    } else {

      return(
        <Results>
          <h3>Search results</h3>
          <Ul>
            {
              numbers.filter(number => number.name.toLowerCase().includes(searchterm) || number.tags.toLowerCase().includes(searchterm))
                .map((record, index) => {
                  const phone = plus27(record.number);
                  return(<Li key={index}><a href={phone}><span>{record.icon} {record.name}</span><span>{formatPhoneNumber(record.number)}</span></a></Li>)
              })
            }
          </Ul>
        </Results>
      )

    }

  } else {

    return(
      <Results>
        <h3>Emergency Numbers</h3>
        <Ul>
          {
            numbers.filter(number => number.emergency === true).map((record, index) => {
              const phone = plus27(record.number);
              return(<Li key={index}><a href={phone}><span>{record.icon} {record.name}</span><span>{formatPhoneNumber(record.number)}</span></a></Li>)
            })
          }
        </Ul>
      </Results>
    )

  }
}

export default Numberlist;
