import React from 'react';
import styled from 'styled-components';
import Granim from 'react-granim';

const Overlay = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  height: 20%;
  background: linear-gradient(0deg, rgba(0,0,50,0.6) 0%, rgba(0,0,50,0.7) 100%);
  z-index: 1000;
`

const Div = styled.div`
  position: fixed;
  display: flex;
  right: 0px;
  justify-content: flex-end;
  width: calc(100vw - 520px);
  height: 100vh;
  z-index: -1;
  border: 50px solid white;
`

const today = new Date();
const hour = today.toLocaleString('en-ZA', {hour: 'numeric', hour12: false, timeZone: 'Africa/Johannesburg'});

const gradients = [
  ['#00000c', '#00000c'],
  ['#020111', '#191621'],
  ['#020111', '#20202c'],
  ['#020111', '#3a3a52'],
  ['#20202c', '#515175'],
  ['#40405c', '#8a76ab'],
  ['#4a4969', '#cd82a0'],
  ['#757abf', '#eab0d1'],
  ['#82addb', '#ebb2b1'],
  ['#94c5f8', '#b1b5ea'],
  ['#94dfff', '#b7eaff'],
  ['#67d1fb', '#9be2fe'],
  ['#38a3d1', '#90dffe'],
  ['#246fa8', '#57c1eb'],
  ['#1e528e', '#2d91c2', ],
  ['#1e528e', '#2473ab'],
  ['#1e528e', '#265889'],
  ['#1e528e', '#e9ce5d'],
  ['#154277', '#b26339'],
  ['#163C52', '#B7490F'],
  ['#071B26', '#240E03'],
  ['#010A10', '#11072f'],
  ['#090401', '#150800'],
  ['#00000c', '#150800'],
];

let hourgradients;

if(hour !== 0) {

  let h;

  if(hour.charAt(0) === '0') {
    h = hour.replace(0,'');
  } else {
    h = hour;
  }

  hourgradients = [];

  for(let i = h; i < 24 ; i++) {
    hourgradients.push(gradients[i]);
  }

  for(let j = 0; j < h ; j++) {
    hourgradients.push(gradients[j]);
  }

} else {
  hourgradients = gradients;
}

const states = {
  "default-state": {
    transitionSpeed: 3600000,
    gradients: hourgradients
  }
}


const BackgroundGranim = (props) => {
  return(
    <Div>
      <Overlay></Overlay>
      <Granim states={states} id="granim" direction="top-bottom">{props.children}</Granim>
    </Div>
  )
}

export default BackgroundGranim;
