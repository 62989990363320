import React from 'react';
import styled from 'styled-components';
import Numberlist from '../numberlist';

const Records = [
  { icon: '🤝', name: 'Scarborough Keepers', number: '0720878940', tags: 'security help', emergency: true},
  { icon: '👮🏾‍♀️', name: 'SAPS Police Simonstown', number: '0217868646', tags: 'police help', emergency: true},
  { icon: '🚒', name: 'Simonstown Fire Station', number: '0214003908', tags: 'fire help', emergency: true},
  { icon: '🚓', name: 'CIS Security', number: '0215521876', tags: 'security', emergency: true},
  { icon: '🚓', name: 'Scarborough Security', number: '0834278571', tags: 'security', emergency: true},
  { icon: '🚑', name: 'CMR Cape Medical Response', number: '0827824444', tags: 'ambulance help doctor', emergency: true},
  { icon: '🚤', name: 'NSRI Sea Rescue Kommetjie', number: '0829905979', tags: 'drown drowning sea rescue', emergency: true},
  { icon: '🐒', name: 'Baboon Hotline', number: '0715886540', tags: 'baboon', emergency: true},
  { icon: '🐍', name: 'Steve Venom Man', number: '0646810779', tags: 'snake venom snakebite', emergency: true},
  { icon: '🍽', name: 'Camel Rock Restaurant', number: '0217801122', tags:'breakfast lunch dinner cafe food', emergency: false},
  { icon: '🍽', name: 'The Village Hub', number: '0217801047', tags:'breakfast lunch dinner restaurant cafe food', emergency: false},
  { icon: '🍽', name: 'Foragers Deli', number: '0217801047', tags:'breakfast lunch restaurant cafe food', emergency: false},
  { icon: '🍽', name: 'Whole Earth Cafe', number: '0217801138', tags:'breakfast lunch dinner restaurant cafe deli food', emergency: false},
  { icon: '👩🏻‍⚕️', name: 'Dr Shani van Der Merwe', number: '0217830644', tags:'doctor medical', emergency: false},
  { icon: '🖥', name: 'Falcon Wireless', number: '0715353482', tags:'internet wifi computer it', emergency: false},
  { icon: '👩🏼‍⚕️', name: 'Longbeach Medical', number: '0217853900', tags:'doctor medical', emergency: false},
  { icon: '⛽️', name: 'Gas24', number: '0217835940', tags:'gas', emergency: false},
  { icon: '🔧', name: 'Plumber - Ian Fraser', number: '0822253026', tags:'plumber water leak', emergency: false},
  { icon: '🔑', name: 'AAA Locksmiths Fishhoek', number: '0217851323', tags:'locksmith lock key keys', emergency: false},
  { icon: '⚡️', name: 'Hart Electrical', number: '0845107777', tags:'electrician electricity electric geyser', emergency: false},
  { icon: '🚛', name: 'Douglas Transport', number: '0217852756', tags:'moving movers', emergency: false},
]

const Div = styled.div`
  overflow-y: auto;
  height: 240px;
  height: 100%;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    margin-bottom: 0px;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 3px;
  }
  ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0,0,0,.5);
      -webkit-box-shadow: 0 0 1px rgba(255,255,255,.9);
  }
`

const PhoneNumbers = (props) => {
  return(
    <Div searchFilter={props.searchFilter}>
      <Numberlist {...props} numbers={Records} />
    </Div>
  )
}

export default PhoneNumbers;
