import React from 'react';
import Clock from 'react-live-clock';

const LocalTime = (props) => {
  return (
    <>
    <Clock format={'dddd HH:mm'} ticking={true} timezone={'Africa/Johannesburg'} />
    </>
  );
}

export default LocalTime;
