import React from 'react';
import styled from 'styled-components';
import SunCalc from 'suncalc2';

import IconSunrise from '../../icons/mountains-sun.svg';
import IconSunset from '../../icons/sunset.svg';
import IconMoonrise from '../../icons/moon.svg';
import IconMoonset from '../../icons/moonset.svg';

const Wrapper = styled.div`
  margin-bottom: 25px;
  @media (max-width: 768px) {

  }
`

const Container = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: flex-start;
`

const Detail = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 20px;
  width: 50%;
  &:first-child {
    padding-right: 0px;
    margin-right: 0px;
  }
  &:last-child {
    margin-right: 0px;
    justify-content: flex-end;
  }
  p {
    color: #24292e;
  }
  img {
    height: 30px;
    opacity: 0.9;
    margin-right: 10px;
  }
`

const minutes = function(dt) {
  return (dt.getMinutes() < 10 ? '0' : '') + dt.getMinutes();
}

const hours = function(dt) {
  return (dt.getHours() < 10 ? '0' : '') + dt.getHours();
}


const Sunrise = (props) => {

  const times = SunCalc.getTimes(new Date(), '-34.198333', '18.375556');
  const moontimes = SunCalc.getMoonTimes(new Date(), '-34.198333', '18.375556');

  /* SUN */

  const sunriseStr = hours(times.sunrise) + ':' + minutes(times.sunrise);
  const sunsetStr = hours(times.sunset) + ':' +  minutes(times.sunset);

  /* MOON */

  let moonrise, moonset;

  if(!isNaN(moontimes.rise)) {
    const mtr = new Date(moontimes.rise)
    moonrise = hours(mtr) + ':' + minutes(moontimes.rise);
  } else {
    moonrise = '-'
  }

  if(!isNaN(moontimes.set)) {
    const mts = new Date(moontimes.set)
    moonset = hours(mts) + ':' + minutes(moontimes.set);
  } else {
    moonset = '-';
  }

  return(
    <Wrapper>
    <Container>
      <Detail><img src={IconSunrise} alt="sunrise" /><p>{sunriseStr}</p></Detail>
      <Detail><img src={IconMoonrise} alt="moonrise" /><p>{moonrise}</p></Detail>
    </Container>
    <Container>
      <Detail><img src={IconSunset} alt="sunset" /><p>{sunsetStr}</p></Detail>
      <Detail><img src={IconMoonset} alt="moonset" /><p>{moonset}</p></Detail>
    </Container>
    </Wrapper>
  )
}

export default Sunrise;
