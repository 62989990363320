import React, { useState } from 'react';
import styled from 'styled-components';
// import Header from '../components/header';
import SEO from '../components/seo';
// import Weather from '../components/weather';
import LocalTime from '../components/localtime';
import PhoneNumbers from '../components/phone_numbers';
import Sunrise from '../components/sunrise';
import BackgroundGranim from '../components/background_granim';

import SearchSVG from '../images/search.svg';
import SearchSVGDark from '../images/search-dark.svg';

const Container = styled.div`
  display: flex;
  height: 100vh;
`

const Input = styled.input`
  background-image: url(${SearchSVG});
  background-repeat: no-repeat;
  background-position: right 10px center;
  &:focus {
    border: 1px solid #223C69;
    background-image: url(${SearchSVGDark});
  }
  &:active {
    border: 1px solid #223C69;
    background-image: url(${SearchSVGDark});
  }
`

const Stats = styled.div`
  padding: 0px 0px 0px 0px;
  display: block;
  align-items: flex-end;
  justify-content: flex-end;
  color: #fff;
`

const Main = styled.main`
  flex-direction: column;
  display: flex;
  width: 520px;
  padding: 50px 0px 0px 50px;
  justify-content: flex-start;
  height: 100vh;
  @media (max-width: 768px) {
    padding: 20px;
  }
`

const StatsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0px 0px 0px 0px;
`

const IndexPage = () => {
  const [ searchFilter, setSearchFilter ] = useState('');
  return(
    <>
      <SEO title="Scarborough" />
      <Container>
        <Main>
          <h1>Scarborough</h1>
          <Stats>
            <StatsContainer>
              <LocalTime />
              <div>
                <Sunrise />
              </div>
            </StatsContainer>
          </Stats>
          <label>
            <Input autoComplete="new-passwssord" value={searchFilter} onChange={e => setSearchFilter(e.target.value)} type="text" id="scarborough-search" name="scarborough-search" placeholder="Search phone directory..." />
          </label>
          <PhoneNumbers searchFilter={searchFilter} />
        </Main>
        <BackgroundGranim />
      </Container>
    </>
  )
}

export default IndexPage
